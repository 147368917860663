import React, { useContext, useState } from 'react';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Button,
  Box,
  useTheme,
  Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import AmazonRegionDropdown from 'components/AmazonRegionDropdown/AmazonRegionDropdown';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { AuthDataContext } from 'contexts/AuthDataContext/AuthDataContext';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AddAsins from 'pages/Common/AddAsins';

function Header({
  title,
  subtitle,
  children,
  minimum,
  transparent,
  showAddAsin,
  onAddAsin,
  onAsinsAdded,
  showCredits,
  tier,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddAsins, setShowAddAsins] = useState(false);
  const client = useApolloClient();
  const { account, user, setUser, hasPermission, hasAbility } = useContext(AuthDataContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [credits, setCredits] = useState(0);
  const history = useHistory();

  const theme = useTheme();

  const processData = (result) => {
    if (result) {
      setCredits(result.getAccountCredits[0]?.creditBalance);
    }
  };

  const GET_CREDITS = gql`
    query GetAccountCredits($accountId: Int) {
      getAccountCredits(accountId: $accountId) {
        creditBalance
      }
    }
  `;

  useQuery(GET_CREDITS, {
    fetchPolicy: 'network-only',
    skip: !account,
    variables: {
      accountId: account.id,
    },
    onCompleted: (result) => processData(result),
    onError: (e) => {
      console.log(e);
    },
  });

  const signOut = async () => {
    await Auth.signOut();
    setUser({});
    client.cache.reset();
    history.push('/');
  };

  const handleMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const handleOpenProfile = () => {
    handleMenuClose();
    history.push('/profile');
  };

  const handleUserManagement = () => {
    handleMenuClose();
    history.push('/accounts');
  };

  const handleSignOut = () => {
    handleMenuClose();
    signOut();
  };

  return (
    <Box sx={{ minHeight: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '40px',
          bgcolor: transparent ? 'transparent' : 'greys.white',
        }}
      >
        <Box>
          {!minimum && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 0, alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
                <Box sx={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '21px' }} data-cy="asin_manage_title">
                  {title}
                </Box>
                {subtitle && (
                  <Box sx={{ pb: 1, fontSize: '13px', opacity: 0.8 }} data-cy="asins_manage_asin_count">
                    {subtitle}
                  </Box>
                )}
              </Box>
              <AmazonRegionDropdown size="32" />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {showAddAsin && hasAbility('asinManagement') && (
            <Button
              onClick={() => {
                if (onAddAsin) {
                  onAddAsin();
                } else {
                  setShowAddAsins(true);
                }
              }}
              data-cy="header_add_asins_button"
            >
              Add ASINs
            </Button>
          )}
          {showCredits && hasAbility('makeTestBuy') && (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, p: 1 }}>
              <Box>
                <Tooltip
                  title={
                    credits === 0 ? (
                      <Box>
                        You have 0 test buy credits
                        <br />
                        Contact support@ipsecure.com to add credits
                      </Box>
                    ) : (
                      <Box>Test Buy Credits Remaining</Box>
                    )
                  }
                  arrow
                  placement="bottom-start"
                  sx={{ cursor: 'default' }}
                >
                  <Box>
                    <SavingsRoundedIcon sx={{ mr: 1, mb: -1, color: theme.palette.greys.black }} />
                    <Typography variant="button">{credits}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          )}
          <IconButton
            sx={{ p: 1, mr: -1, ml: -2, color: 'greys.black' }}
            onClick={handleMenu}
            size="large"
            data-cy="profile_button"
          >
            <AccountCircleIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleMenuClose}
            variant="menu"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ mt: 2, ml: 3, mr: 3, mb: 3, color: 'greys.darkGrey' }}>
              <Typography sx={{ fontSize: '12px' }}>Signed in as</Typography>
              {(user?.profile?.firstName || user?.profile?.lastName) && (
                <Typography sx={{ fontWeight: '600' }}>
                  {`${user?.profile?.firstName} ${user?.profile?.lastName}`}
                </Typography>
              )}
              <Typography sx={{ fontSize: '12px' }}>{user?.profile?.email}</Typography>
            </Box>
            <Divider light sx={{ mt: 1, mb: 1 }} />
            <MenuItem onClick={handleOpenProfile} data-cy="profile_button_profile">
              Profile
            </MenuItem>
            {hasPermission('userManagement') && (
              <MenuItem onClick={handleUserManagement}>User Management</MenuItem>
            )}
            <Divider light sx={{ mt: 1, mb: 1 }} />
            <MenuItem onClick={handleSignOut} data-cy="sign_out_button">
              Sign Out
            </MenuItem>
            <Box sx={{ mt: 1, mb: 1 }} />
          </Menu>
        </Box>
      </Box>
      {children && <Box sx={{ bgcolor: 'greys.white' }}>{children}</Box>}
      {showAddAsins && (
        <AddAsins
          addAsinsMode
          isOpen={showAddAsins}
          onClose={(result) => {
            setShowAddAsins(false);
            if (result !== false && onAsinsAdded) {
              onAsinsAdded();
            }
          }}
          tier={tier}
        />
      )}
    </Box>
  );
}

Header.defaultProps = {
  subtitle: null,
  minimum: false,
  transparent: false,
  showAddAsin: false,
  onAddAsin: undefined,
  onAsinsAdded: undefined,
  showCredits: false,
};

Header.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  minimum: PropTypes.bool,
  transparent: PropTypes.bool,
  showAddAsin: PropTypes.bool,
  onAddAsin: PropTypes.func,
  onAsinsAdded: PropTypes.func,
  showCredits: PropTypes.bool,
};

export default Header;
