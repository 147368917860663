import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box, FormControlLabel, Checkbox, Button, Select, MenuItem, useTheme } from '@mui/material';
import { LoadingButton, ConfirmationDialog } from 'components';
import { useMutation, useQuery, gql } from '@apollo/client';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { Buffer } from 'buffer';
import { Image } from 'image-js';
import { ALL_REGIONS } from 'utils/constants';
import { validEmailAddress } from 'utils/strings';

const ClientProfile = ({ client, tenant }) => {
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState(client?.name);
  const [inventory, setInventory] = useState(client?.fetchInventory);
  const [demo, setDemo] = useState(client?.demo ?? false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [externalEnforcementRoutingEmail, setExternalEnforcementRoutingEmail] = useState(client?.externalEnforcementRoutingEmail);
  const [saved, setSaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [deleteConfirmationEnforcements, setDeleteConfirmationEnforcements] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { account, updateAccount, hasPermission } = useContext(AuthDataContext);
  const [logo, setLogo] = useState(client?.logo ?? null);
  const theme = useTheme();

  const isAllRegionsSelected = (list) => {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i].id === ALL_REGIONS) {
        return true;
      }
    }
    return false;
  }

  const processData = (data) => {
    if (data?.getFeatures) {
      setFeatures(data.getFeatures);

      const chosenFeatures = [];
      for (let i = 0; i < client.configuredFeatures?.length; i += 1) {
        for (let j = 0; j < data.getFeatures.length; j += 1) {
          if (data.getFeatures[j].id === client.configuredFeatures[i].id) {
            chosenFeatures.push(data.getFeatures[j]);
            break;
          }
        }
      }
      for (let i = 0; i < tenant.configuredFeatures?.length; i += 1) {
        for (let j = 0; j < data.getFeatures.length; j += 1) {
          if (data.getFeatures[j].id === tenant.configuredFeatures[i].id) {
            if (!chosenFeatures.includes(data.getFeatures[j])) {
              chosenFeatures.push(data.getFeatures[j]);
              break;
            }
          }
        }
      }
      setSelectedFeatures(chosenFeatures);

      let regionsToUse = tenant.configuredRegions.concat();
      if (isAllRegionsSelected(regionsToUse)) {
        regionsToUse = data.getRegions;
      } else {
        // add the All option to the list in this case
        for (let i = 0; i < data.getRegions.length; i += 1) {
          if (data.getRegions[i].id === ALL_REGIONS) {
            regionsToUse.unshift(data.getRegions[i]);
            break;
          }
        }
      }
      setRegions(regionsToUse);

      const chosenRegions = [];
      for (let i = 0; i < client.configuredRegions?.length; i += 1) {
        for (let j = 0; j < regionsToUse.length; j += 1) {
          if (regionsToUse[j].id === client.configuredRegions[i].id) {
            chosenRegions.push(regionsToUse[j]);
            break;
          }
        }
      }
      setSelectedRegions(chosenRegions);
      setLoading(false);
    }
  };

  const GET_INFO_QUERY = gql`
    query GetInfo {
      getFeatures {
        id
        name
        description
      }
      getRegions {
        baseUrl
        id
        currency
        iso
        name
        nativeName
        asinCount
      }
    }
  `;

  const { refetch } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleResetForm = () => {
    setName(client?.name);
    setInventory(client?.fetchInventory);
    setDemo(client?.demo);
    setSaved(false);
    setUnsavedChanges(false);
    setLogo(client?.logo ?? null);
    setExternalEnforcementRoutingEmail(client?.externalEnforcementRoutingEmail);
    refetch();
  }

  const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient($id: ID!, $name: String, $fetchInventory: Boolean, $logo: String, $features: [Int], 
                          $regions: [String], $externalEnforcementRoutingEmail: String, $demo: Boolean) {
      updateAccount(
        id: $id, name: $name, fetchInventory: $fetchInventory, logo: $logo, 
        features: $features, regions: $regions, externalEnforcementRoutingEmail: $externalEnforcementRoutingEmail,
        demo: $demo
      ) 
    }
  `;

  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const REMOVE_ENFORCEMENTS_MUTATION = gql`
    mutation RemoveAllEnforcements($accountId: Int!) {
      removeAllEnforcementItems(accountId: $accountId) 
    }
  `;

  const [removeEnforcements] = useMutation(REMOVE_ENFORCEMENTS_MUTATION, {
    onError: (e) => {
      setDeleting(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const routingEnabled = () => {
    for (let i = 0; i < selectedFeatures.length; i += 1) {
      const feature = selectedFeatures[i];
      if (feature.name === 'externalEnforcementRouting') {
        return true;
      }
    }
    return false;
  }

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (name.length > 100) {
      errors.push({ name: 'Name must be under 100 characters' });
    }
    if (hasPermission('tenantManagement') && routingEnabled() && !validEmailAddress(externalEnforcementRoutingEmail)) {
      errors.push({ externalEnforcementRoutingEmail: 'Invalid email address' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm();
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        const regionsToUse = isAllRegionsSelected(selectedRegions) ? [ALL_REGIONS] : selectedRegions.map(r => r.id);
        const updatedRegions = isAllRegionsSelected(selectedRegions) ? { id: ALL_REGIONS } : selectedRegions;
        const params = {
          id: client.id,
          name,
          fetchInventory: hasPermission('tenantManagement') ? inventory : null,
          logo,
          regions: regionsToUse,
          externalEnforcementRoutingEmail,
          demo,
        };
        if (hasPermission('tenantManagement')) {
          params.features = selectedFeatures.map(f => f.id);
        }
        updateClient({
          variables: params,
          onCompleted: () => {
            if (client.id === account.id) {
              updateAccount();
            }
            setLoading(false);
            setSaved(true);
            setUnsavedChanges(false);
          },
          update(cache) {
            cache.modify({
              id: cache.identify(client),
              fields: {
                name() {
                  return name;
                },
                fetchInventory() {
                  return inventory;
                },
                logo() {
                  return logo;
                },
                features() {
                  return selectedFeatures;
                },
                configuredRegions() {
                  return updatedRegions;
                },
                externalEnforcementRoutingEmail() {
                  return externalEnforcementRoutingEmail;
                },
                demo() {
                  return demo;
                }
              },
            });
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
      }
    }
  }

  const handleSelectLogo = (e) => {
    if (e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = async readerEvent => {
        const content = readerEvent.target.result; // this is the content!
        // eslint-disable-next-line new-cap
        const imageBuffer = new Buffer.from(content, 'binary');
        const loadedImage = await Image.load(imageBuffer);
        let converted = null;
        if (loadedImage.width > 640) {
          converted = await loadedImage.resize({ width: 640 }).toBase64();
        } else {
          converted = await loadedImage.toBase64();
        }
        const src = `data:image/png;base64,${converted}`;
        setLogo(src);
      }
      setSaved(false);
      setUnsavedChanges(true);
    }
  }

  const handleDeleteEnforcements = () => {
    setDeleting(true);
    removeEnforcements({
      variables: { accountId: client.id },
      onCompleted: () => {
        setDeleting(false);
        setDeleteConfirmationEnforcements(false);
      },
    });
  }

  return (
    <form onSubmit={handleSubmit} onReset={handleResetForm}>
      {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 1, fontFamily: theme.typography.fontFamily, }}>
        <TextField
          disabled={loading}
          required
          id="client-profile-name"
          data-cy="client_name_field"
          label="Client name"
          placeholder="client name"
          type="text"
          value={name || ''}
          onChange={(e) => { setName(e.target.value); setSaved(false); setUnsavedChanges(true) }}
          error={Boolean(formErrors.name)}
          helperText={formErrors.name}
          sx={{ width: '300px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {hasPermission('tenantManagement') && (
            <Box sx={{ my: 1 }}>
              <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Features</Box>
              <Select
                disabled={loading}
                displayEmpty
                multiple
                value={selectedFeatures}
                fullWidth
                sx={{ minWidth: '15vw', marginTop: '0px' }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return 'None';
                  }
                  return selected.map(f => {
                    if (tenant.configuredFeatures.filter(item => item.id === f.id).length > 0) {
                      return `${f.description} (Enabled in Tenant)`;
                    }
                    return f.description;
                  }).join(', ');
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedFeatures(value);
                  setUnsavedChanges(true);
                }}
                data-cy="features"
              >
                {features.map((f) => (
                  <MenuItem key={f.id} value={f} disabled={tenant.configuredFeatures.filter(item => item.id === f.id).length > 0}>
                    <Checkbox
                      size="small"
                      sx={{ p: 0.5, m: 0 }}
                      checked={selectedFeatures.includes(f)}
                      data-cy="features_checkbox"
                    />
                    {f.description}{(tenant.configuredFeatures.filter(item => item.id === f.id).length > 0) && ' (Enabled in Tenant)'}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {hasPermission('clientManagement') && (
            <Box sx={{ mt: 1, mb: 2 }}>
              <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Regions</Box>
              <Select
                disabled={loading}
                displayEmpty
                multiple
                value={selectedRegions}
                fullWidth
                sx={{ minWidth: '15vw', marginTop: '0px' }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return 'None';
                  }
                  if (isAllRegionsSelected(selectedRegions)) {
                    return 'All Available Regions';
                  }
                  return selected.map(r => `${r.name}`).join(', ');
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedRegions(value);
                  setUnsavedChanges(true);
                }}
                data-cy="regions"
              >
                {regions.map((r) => (
                  <MenuItem key={r.id} value={r} disabled={isAllRegionsSelected(selectedRegions) && r.id !== ALL_REGIONS} divider={r.id === ALL_REGIONS}>
                    <Checkbox
                      size="small"
                      sx={{ p: 0.5, m: 0 }}
                      checked={selectedRegions.includes(r)}
                      data-cy="regions_checkbox"
                    />
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {r.id !== ALL_REGIONS && (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <img
                            style={{ minWidth: '16px', minHeight: '16px', filter: 'drop-shadow(0px 0px 0px black)' }}
                            alt={`${r.id} flag`}
                            src={`https://flagsapi.com/${r.iso}/flat/16.png`}
                          />
                        </Box>
                      )}
                      <Box sx={{ fontWeight: r.id !== ALL_REGIONS ? 400 : 700 }}>
                        {r.id === ALL_REGIONS && 'All Available Regions'}
                        {r.id !== ALL_REGIONS && `${r.name} (${r.nativeName})`}
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {hasPermission('tenantManagement') && routingEnabled() && (
            <TextField
              value={externalEnforcementRoutingEmail}
              label="External Enforcement Routing Email Address"
              inputProps={{
                type: "email",
              }}
              error={Boolean(formErrors.externalEnforcementRoutingEmail)}
              helperText={formErrors.externalEnforcementRoutingEmail}
              onChange={(e) => { setExternalEnforcementRoutingEmail(e.target.value); setSaved(false); setUnsavedChanges(true) }}
              sx={{ width: '300px' }}
            />
          )}
          {hasPermission('tenantManagement') && (
            <Box sx={{ my: 1 }}>
              <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Account Type</Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Select
                  disabled={loading}
                  displayEmpty
                  value={demo}
                  fullWidth
                  sx={{ minWidth: '15vw', marginTop: '0px' }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDemo(value);
                    setUnsavedChanges(true);
                  }}
                  data-cy="demo_mode"
                >
                  <MenuItem key={0} value={false}>
                    <Box>Regular Account</Box>
                  </MenuItem>
                  <MenuItem key={1} value>
                    <Box><b>Demo</b> Account</Box>
                  </MenuItem>
                </Select>
                {client?.demo && demo && (
                  <LoadingButton
                    disabled={deleting}
                    onClick={(e) => { e.preventDefault(); setDeleteConfirmationEnforcements(true) }}
                    variant="contained"
                    data-cy="delete_confirm_button"
                    sx={{
                      px: 3,
                      bgcolor: 'error.main',
                      '&:hover': {
                        bgcolor: 'error.dark',
                      },
                      '&:disabled': {
                        bgcolor: 'error.light',
                      },
                    }}
                  >
                    Delete&nbsp;Enforcements
                  </LoadingButton>
                )}
              </Box>
            </Box>
          )}
          {hasPermission('tenantManagement') && (
            <Box
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: '400',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                sx={{ color: 'greys.darkGrey', mr: '5px' }}
                control={<Checkbox checked={inventory} onChange={(e) => { setInventory(e.target.checked); setSaved(false); setUnsavedChanges(true) }} />}
                label="Fetch Inventory"
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              {logo && (
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, background: tenant.navColor, borderRadius: '8px' }}>
                  <Box
                    component="img"
                    sx={{ maxWidth: '110px', maxHeight: '50px', objectFit: 'contain' }}
                    src={logo}
                  />
                </Box>
              )}
              {!logo && (
                <Box
                  component="div"
                  sx={{ width: '140px', p: 2, background: tenant.navColor, fontSize: '12px', color: '#fff', textAlign: 'center', borderRadius: '8px' }}
                  alt="login logo"
                >
                  Upload Image
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ alignSelf: 'flex-start' }}
                >
                  Upload PNG Logo
                  <input
                    hidden
                    accept="image/png"
                    type="file"
                    onChange={handleSelectLogo}
                  />
                </Button>
                {logo && (
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={() => { setLogo(null); setSaved(false); setUnsavedChanges(true) }}
                  >
                    Remove Logo
                  </Button>
                )}
              </Box>
              <Box sx={{ fontSize: '12px' }}>
                Optional logo used in the side navigation underneath the Tenant logo.
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            data-cy="client_update"
          >
            Save Client Profile
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={loading}
            type="reset"
            onClick={() => {
              setSaved(false);
              setUnsavedChanges(false);
            }}
          >
            Reset
          </LoadingButton>
        </Box>
        {saved && <Box data-cy="client_profile_saved" sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.green }}>Client Profile Saved</Box>}
        {unsavedChanges && <Box sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.red }}>Unsaved Changes</Box>}
        {deleteConfirmationEnforcements && (
          <ConfirmationDialog
            open
            busy={deleting}
            title="Delete ALL Enforcements"
            message={
              <Box sx={{ mt: 2 }}>
                Are you sure you wish to delete all of the enforcements in this account?
                <br />
                <b>THIS CANNOT BE UNDONE.</b>
                <br />
                This may take several minutes.
              </Box>
            }
            okTitle="Delete"
            destructive
            confirmDestructive="DELETE"
            onClose={(confirmed) => {
              if (confirmed) {
                handleDeleteEnforcements();
              } else {
                setDeleteConfirmationEnforcements(null);
              }
            }}
          />
        )}
      </Box>
    </form>
  );
}

ClientProfile.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ClientProfile;
