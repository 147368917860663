import React, { useState, useContext, useEffect } from 'react';
import { ToastDataContext, RegionDataContext } from 'contexts';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Loading, FilterDropdown, MapGoogle } from 'components';
import markerBlue from 'assets/marker-blue.svg';
import markerRed from 'assets/marker-red.svg';
import markerGray from 'assets/marker-gray.svg';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { gql, useLazyQuery } from '@apollo/client';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

const SellersMap = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const { regionIso } = useContext(RegionDataContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellerProfile, setSellerProfile] = useState(null);
  const [asins, setAsins] = useState([]);
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [asin, setAsin] = useState(null);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [categories, setCategories] = useState(null);
  const typeOptions = [{ value: 1, title: 'Low Scoring' }];
  const [showReferenceAddresses, setShowReferenceAddresses] = useState(true);
  const [referenceAddresses, setReferenceAddresses] = useState([]);

  const getCoordinateLookup = () => {
    const coordinates = {};
    // List partially from CSV that Google publishes, but doesn't expose via the API
    coordinates.AD = { latitude: 42.546245, longitude: 1.601554, zoom: 5 };
    coordinates.AE = { latitude: 23.424076, longitude: 53.847818, zoom: 6 };
    coordinates.AF = { latitude: 33.93911, longitude: 67.709953, zoom: 5 };
    coordinates.AG = { latitude: 17.060816, longitude: -61.796428, zoom: 5 };
    coordinates.AI = { latitude: 18.220554, longitude: -63.068615, zoom: 5 };
    coordinates.AL = { latitude: 41.153332, longitude: 20.168331, zoom: 5 };
    coordinates.AM = { latitude: 40.069099, longitude: 45.038189, zoom: 5 };
    coordinates.AN = { latitude: 12.226079, longitude: -69.060087, zoom: 5 };
    coordinates.AO = { latitude: -11.202692, longitude: 17.873887, zoom: 5 };
    coordinates.AQ = { latitude: -75.250973, longitude: -0.071389, zoom: 5 };
    coordinates.AR = { latitude: -38.416097, longitude: -63.616672, zoom: 5 };
    coordinates.AS = { latitude: -14.270972, longitude: -170.132217, zoom: 5 };
    coordinates.AT = { latitude: 47.516231, longitude: 14.550072, zoom: 5 };
    coordinates.AU = { latitude: -25.274398, longitude: 133.775136, zoom: 3 };
    coordinates.AW = { latitude: 12.52111, longitude: -69.968338, zoom: 5 };
    coordinates.AZ = { latitude: 40.143105, longitude: 47.576927, zoom: 5 };
    coordinates.BA = { latitude: 43.915886, longitude: 17.679076, zoom: 5 };
    coordinates.BB = { latitude: 13.193887, longitude: -59.543198, zoom: 5 };
    coordinates.BD = { latitude: 23.684994, longitude: 90.356331, zoom: 5 };
    coordinates.BE = { latitude: 50.503887, longitude: 4.469936, zoom: 6 };
    coordinates.BF = { latitude: 12.238333, longitude: -1.561593, zoom: 5 };
    coordinates.BG = { latitude: 42.733883, longitude: 25.48583, zoom: 5 };
    coordinates.BH = { latitude: 25.930414, longitude: 50.637772, zoom: 5 };
    coordinates.BI = { latitude: -3.373056, longitude: 29.918886, zoom: 5 };
    coordinates.BJ = { latitude: 9.30769, longitude: 2.315834, zoom: 5 };
    coordinates.BM = { latitude: 32.321384, longitude: -64.75737, zoom: 5 };
    coordinates.BN = { latitude: 4.535277, longitude: 114.727669, zoom: 5 };
    coordinates.BO = { latitude: -16.290154, longitude: -63.588653, zoom: 5 };
    coordinates.BR = { latitude: -14.235004, longitude: -51.92528, zoom: 3 };
    coordinates.BS = { latitude: 25.03428, longitude: -77.39628, zoom: 5 };
    coordinates.BT = { latitude: 27.514162, longitude: 90.433601, zoom: 5 };
    coordinates.BV = { latitude: -54.423199, longitude: 3.413194, zoom: 5 };
    coordinates.BW = { latitude: -22.328474, longitude: 24.684866, zoom: 5 };
    coordinates.BY = { latitude: 53.709807, longitude: 27.953389, zoom: 5 };
    coordinates.BZ = { latitude: 17.189877, longitude: -88.49765, zoom: 5 };
    coordinates.CA = { latitude: 59.130366, longitude: -106.346771, zoom: 2 };
    coordinates.CC = { latitude: -12.164165, longitude: 96.870956, zoom: 5 };
    coordinates.CD = { latitude: -4.038333, longitude: 21.758664, zoom: 5 };
    coordinates.CF = { latitude: 6.611111, longitude: 20.939444, zoom: 5 };
    coordinates.CG = { latitude: -0.228021, longitude: 15.827659, zoom: 5 };
    coordinates.CH = { latitude: 46.818188, longitude: 8.227512, zoom: 5 };
    coordinates.CI = { latitude: 7.539989, longitude: -5.54708, zoom: 5 };
    coordinates.CK = { latitude: -21.236736, longitude: -159.777671, zoom: 5 };
    coordinates.CL = { latitude: -35.675147, longitude: -71.542969, zoom: 5 };
    coordinates.CM = { latitude: 7.369722, longitude: 12.354722, zoom: 5 };
    coordinates.CN = { latitude: 35.86166, longitude: 104.195397, zoom: 3 };
    coordinates.CO = { latitude: 4.570868, longitude: -74.297333, zoom: 5 };
    coordinates.CR = { latitude: 9.748917, longitude: -83.753428, zoom: 5 };
    coordinates.CU = { latitude: 21.521757, longitude: -77.781167, zoom: 5 };
    coordinates.CV = { latitude: 16.002082, longitude: -24.013197, zoom: 5 };
    coordinates.CX = { latitude: -10.447525, longitude: 105.690449, zoom: 5 };
    coordinates.CY = { latitude: 35.126413, longitude: 33.429859, zoom: 5 };
    coordinates.CZ = { latitude: 49.817492, longitude: 15.472962, zoom: 5 };
    coordinates.DE = { latitude: 51.165691, longitude: 10.451526, zoom: 5 };
    coordinates.DJ = { latitude: 11.825138, longitude: 42.590275, zoom: 5 };
    coordinates.DK = { latitude: 56.26392, longitude: 9.501785, zoom: 5 };
    coordinates.DM = { latitude: 15.414999, longitude: -61.370976, zoom: 5 };
    coordinates.DO = { latitude: 18.735693, longitude: -70.162651, zoom: 5 };
    coordinates.DZ = { latitude: 28.033886, longitude: 1.659626, zoom: 5 };
    coordinates.EC = { latitude: -1.831239, longitude: -78.183406, zoom: 5 };
    coordinates.EE = { latitude: 58.595272, longitude: 25.013607, zoom: 5 };
    coordinates.EG = { latitude: 26.820553, longitude: 30.802498, zoom: 5 };
    coordinates.EH = { latitude: 24.215527, longitude: -12.885834, zoom: 5 };
    coordinates.ER = { latitude: 15.179384, longitude: 39.782334, zoom: 5 };
    coordinates.ES = { latitude: 40.463667, longitude: -3.74922, zoom: 5 };
    coordinates.ET = { latitude: 9.145, longitude: 40.489673, zoom: 5 };
    coordinates.FI = { latitude: 61.92411, longitude: 25.748151, zoom: 5 };
    coordinates.FJ = { latitude: -16.578193, longitude: 179.414413, zoom: 5 };
    coordinates.FK = { latitude: -51.796253, longitude: -59.523613, zoom: 5 };
    coordinates.FM = { latitude: 7.425554, longitude: 150.550812, zoom: 5 };
    coordinates.FO = { latitude: 61.892635, longitude: -6.911806, zoom: 5 };
    coordinates.FR = { latitude: 46.227638, longitude: 2.213749, zoom: 5 };
    coordinates.GA = { latitude: -0.803689, longitude: 11.609444, zoom: 5 };
    coordinates.GB = { latitude: 55.378051, longitude: -3.435973, zoom: 4 };
    coordinates.GD = { latitude: 12.262776, longitude: -61.604171, zoom: 5 };
    coordinates.GE = { latitude: 42.315407, longitude: 43.356892, zoom: 5 };
    coordinates.GF = { latitude: 3.933889, longitude: -53.125782, zoom: 5 };
    coordinates.GG = { latitude: 49.465691, longitude: -2.585278, zoom: 5 };
    coordinates.GH = { latitude: 7.946527, longitude: -1.023194, zoom: 5 };
    coordinates.GI = { latitude: 36.137741, longitude: -5.345374, zoom: 5 };
    coordinates.GL = { latitude: 71.706936, longitude: -42.604303, zoom: 5 };
    coordinates.GM = { latitude: 13.443182, longitude: -15.310139, zoom: 5 };
    coordinates.GN = { latitude: 9.945587, longitude: -9.696645, zoom: 5 };
    coordinates.GP = { latitude: 16.995971, longitude: -62.067641, zoom: 5 };
    coordinates.GQ = { latitude: 1.650801, longitude: 10.267895, zoom: 5 };
    coordinates.GR = { latitude: 39.074208, longitude: 21.824312, zoom: 5 };
    coordinates.GS = { latitude: -54.429579, longitude: -36.587909, zoom: 5 };
    coordinates.GT = { latitude: 15.783471, longitude: -90.230759, zoom: 5 };
    coordinates.GU = { latitude: 13.444304, longitude: 144.793731, zoom: 5 };
    coordinates.GW = { latitude: 11.803749, longitude: -15.180413, zoom: 5 };
    coordinates.GY = { latitude: 4.860416, longitude: -58.93018, zoom: 5 };
    coordinates.GZ = { latitude: 31.354676, longitude: 34.308825, zoom: 5 };
    coordinates.HK = { latitude: 22.396428, longitude: 114.109497, zoom: 5 };
    coordinates.HM = { latitude: -53.08181, longitude: 73.504158, zoom: 5 };
    coordinates.HN = { latitude: 15.199999, longitude: -86.241905, zoom: 5 };
    coordinates.HR = { latitude: 45.1, longitude: 15.2, zoom: 5 };
    coordinates.HT = { latitude: 18.971187, longitude: -72.285215, zoom: 5 };
    coordinates.HU = { latitude: 47.162494, longitude: 19.503304, zoom: 5 };
    coordinates.ID = { latitude: -0.789275, longitude: 113.921327, zoom: 5 };
    coordinates.IE = { latitude: 53.41291, longitude: -8.24389, zoom: 5 };
    coordinates.IL = { latitude: 31.046051, longitude: 34.851612, zoom: 5 };
    coordinates.IM = { latitude: 54.236107, longitude: -4.548056, zoom: 5 };
    coordinates.IN = { latitude: 20.593684, longitude: 78.96288, zoom: 4 };
    coordinates.IO = { latitude: -6.343194, longitude: 71.876519, zoom: 5 };
    coordinates.IQ = { latitude: 33.223191, longitude: 43.679291, zoom: 5 };
    coordinates.IR = { latitude: 32.427908, longitude: 53.688046, zoom: 5 };
    coordinates.IS = { latitude: 64.963051, longitude: -19.020835, zoom: 5 };
    coordinates.IT = { latitude: 41.87194, longitude: 12.56738, zoom: 5 };
    coordinates.JE = { latitude: 49.214439, longitude: -2.13125, zoom: 5 };
    coordinates.JM = { latitude: 18.109581, longitude: -77.297508, zoom: 5 };
    coordinates.JO = { latitude: 30.585164, longitude: 36.238414, zoom: 5 };
    coordinates.JP = { latitude: 36.204824, longitude: 138.252924, zoom: 5 };
    coordinates.KE = { latitude: -0.023559, longitude: 37.906193, zoom: 5 };
    coordinates.KG = { latitude: 41.20438, longitude: 74.766098, zoom: 5 };
    coordinates.KH = { latitude: 12.565679, longitude: 104.990963, zoom: 5 };
    coordinates.KI = { latitude: -3.370417, longitude: -168.734039, zoom: 5 };
    coordinates.KM = { latitude: -11.875001, longitude: 43.872219, zoom: 5 };
    coordinates.KN = { latitude: 17.357822, longitude: -62.782998, zoom: 5 };
    coordinates.KP = { latitude: 40.339852, longitude: 127.510093, zoom: 5 };
    coordinates.KR = { latitude: 35.907757, longitude: 127.766922, zoom: 5 };
    coordinates.KW = { latitude: 29.31166, longitude: 47.481766, zoom: 5 };
    coordinates.KY = { latitude: 19.513469, longitude: -80.566956, zoom: 5 };
    coordinates.KZ = { latitude: 48.019573, longitude: 66.923684, zoom: 5 };
    coordinates.LA = { latitude: 19.85627, longitude: 102.495496, zoom: 5 };
    coordinates.LB = { latitude: 33.854721, longitude: 35.862285, zoom: 5 };
    coordinates.LC = { latitude: 13.909444, longitude: -60.978893, zoom: 5 };
    coordinates.LI = { latitude: 47.166, longitude: 9.555373, zoom: 5 };
    coordinates.LK = { latitude: 7.873054, longitude: 80.771797, zoom: 5 };
    coordinates.LR = { latitude: 6.428055, longitude: -9.429499, zoom: 5 };
    coordinates.LS = { latitude: -29.609988, longitude: 28.233608, zoom: 5 };
    coordinates.LT = { latitude: 55.169438, longitude: 23.881275, zoom: 5 };
    coordinates.LU = { latitude: 49.815273, longitude: 6.129583, zoom: 5 };
    coordinates.LV = { latitude: 56.879635, longitude: 24.603189, zoom: 5 };
    coordinates.LY = { latitude: 26.3351, longitude: 17.228331, zoom: 5 };
    coordinates.MA = { latitude: 31.791702, longitude: -7.09262, zoom: 5 };
    coordinates.MC = { latitude: 43.750298, longitude: 7.412841, zoom: 5 };
    coordinates.MD = { latitude: 47.411631, longitude: 28.369885, zoom: 5 };
    coordinates.ME = { latitude: 42.708678, longitude: 19.37439, zoom: 5 };
    coordinates.MG = { latitude: -18.766947, longitude: 46.869107, zoom: 5 };
    coordinates.MH = { latitude: 7.131474, longitude: 171.184478, zoom: 5 };
    coordinates.MK = { latitude: 41.608635, longitude: 21.745275, zoom: 5 };
    coordinates.ML = { latitude: 17.570692, longitude: -3.996166, zoom: 5 };
    coordinates.MM = { latitude: 21.913965, longitude: 95.956223, zoom: 5 };
    coordinates.MN = { latitude: 46.862496, longitude: 103.846656, zoom: 5 };
    coordinates.MO = { latitude: 22.198745, longitude: 113.543873, zoom: 5 };
    coordinates.MP = { latitude: 17.33083, longitude: 145.38469, zoom: 5 };
    coordinates.MQ = { latitude: 14.641528, longitude: -61.024174, zoom: 5 };
    coordinates.MR = { latitude: 21.00789, longitude: -10.940835, zoom: 5 };
    coordinates.MS = { latitude: 16.742498, longitude: -62.187366, zoom: 5 };
    coordinates.MT = { latitude: 35.937496, longitude: 14.375416, zoom: 5 };
    coordinates.MU = { latitude: -20.348404, longitude: 57.552152, zoom: 5 };
    coordinates.MV = { latitude: 3.202778, longitude: 73.22068, zoom: 5 };
    coordinates.MW = { latitude: -13.254308, longitude: 34.301525, zoom: 5 };
    coordinates.MX = { latitude: 23.634501, longitude: -102.552784, zoom: 4 };
    coordinates.MY = { latitude: 4.210484, longitude: 101.975766, zoom: 5 };
    coordinates.MZ = { latitude: -18.665695, longitude: 35.529562, zoom: 5 };
    coordinates.NA = { latitude: -22.95764, longitude: 18.49041, zoom: 5 };
    coordinates.NC = { latitude: -20.904305, longitude: 165.618042, zoom: 5 };
    coordinates.NE = { latitude: 17.607789, longitude: 8.081666, zoom: 5 };
    coordinates.NF = { latitude: -29.040835, longitude: 167.954712, zoom: 5 };
    coordinates.NG = { latitude: 9.081999, longitude: 8.675277, zoom: 5 };
    coordinates.NI = { latitude: 12.865416, longitude: -85.207229, zoom: 5 };
    coordinates.NL = { latitude: 52.132633, longitude: 5.291266, zoom: 6 };
    coordinates.NO = { latitude: 60.472024, longitude: 8.468946, zoom: 5 };
    coordinates.NP = { latitude: 28.394857, longitude: 84.124008, zoom: 5 };
    coordinates.NR = { latitude: -0.522778, longitude: 166.931503, zoom: 5 };
    coordinates.NU = { latitude: -19.054445, longitude: -169.867233, zoom: 5 };
    coordinates.NZ = { latitude: -40.900557, longitude: 174.885971, zoom: 5 };
    coordinates.OM = { latitude: 21.512583, longitude: 55.923255, zoom: 5 };
    coordinates.PA = { latitude: 8.537981, longitude: -80.782127, zoom: 5 };
    coordinates.PE = { latitude: -9.189967, longitude: -75.015152, zoom: 5 };
    coordinates.PF = { latitude: -17.679742, longitude: -149.406843, zoom: 5 };
    coordinates.PG = { latitude: -6.314993, longitude: 143.95555, zoom: 5 };
    coordinates.PH = { latitude: 12.879721, longitude: 121.774017, zoom: 5 };
    coordinates.PK = { latitude: 30.375321, longitude: 69.345116, zoom: 5 };
    coordinates.PL = { latitude: 51.919438, longitude: 19.145136, zoom: 5 };
    coordinates.PM = { latitude: 46.941936, longitude: -56.27111, zoom: 5 };
    coordinates.PN = { latitude: -24.703615, longitude: -127.439308, zoom: 5 };
    coordinates.PR = { latitude: 18.220833, longitude: -66.590149, zoom: 5 };
    coordinates.PS = { latitude: 31.952162, longitude: 35.233154, zoom: 5 };
    coordinates.PT = { latitude: 39.399872, longitude: -8.224454, zoom: 5 };
    coordinates.PW = { latitude: 7.51498, longitude: 134.58252, zoom: 5 };
    coordinates.PY = { latitude: -23.442503, longitude: -58.443832, zoom: 5 };
    coordinates.QA = { latitude: 25.354826, longitude: 51.183884, zoom: 5 };
    coordinates.RE = { latitude: -21.115141, longitude: 55.536384, zoom: 5 };
    coordinates.RO = { latitude: 45.943161, longitude: 24.96676, zoom: 5 };
    coordinates.RS = { latitude: 44.016521, longitude: 21.005859, zoom: 5 };
    coordinates.RU = { latitude: 61.52401, longitude: 105.318756, zoom: 2 };
    coordinates.RW = { latitude: -1.940278, longitude: 29.873888, zoom: 5 };
    coordinates.SA = { latitude: 23.885942, longitude: 45.079162, zoom: 4 };
    coordinates.SB = { latitude: -9.64571, longitude: 160.156194, zoom: 5 };
    coordinates.SC = { latitude: -4.679574, longitude: 55.491977, zoom: 5 };
    coordinates.SD = { latitude: 12.862807, longitude: 30.217636, zoom: 5 };
    coordinates.SE = { latitude: 60.128161, longitude: 18.643501, zoom: 3 };
    coordinates.SG = { latitude: 1.352083, longitude: 103.819836, zoom: 9 };
    coordinates.SH = { latitude: -24.143474, longitude: -10.030696, zoom: 5 };
    coordinates.SI = { latitude: 46.151241, longitude: 14.995463, zoom: 5 };
    coordinates.SJ = { latitude: 77.553604, longitude: 23.670272, zoom: 5 };
    coordinates.SK = { latitude: 48.669026, longitude: 19.699024, zoom: 5 };
    coordinates.SL = { latitude: 8.460555, longitude: -11.779889, zoom: 5 };
    coordinates.SM = { latitude: 43.94236, longitude: 12.457777, zoom: 5 };
    coordinates.SN = { latitude: 14.497401, longitude: -14.452362, zoom: 5 };
    coordinates.SO = { latitude: 5.152149, longitude: 46.199616, zoom: 5 };
    coordinates.SR = { latitude: 3.919305, longitude: -56.027783, zoom: 5 };
    coordinates.ST = { latitude: 0.18636, longitude: 6.613081, zoom: 5 };
    coordinates.SV = { latitude: 13.794185, longitude: -88.89653, zoom: 5 };
    coordinates.SY = { latitude: 34.802075, longitude: 38.996815, zoom: 5 };
    coordinates.SZ = { latitude: -26.522503, longitude: 31.465866, zoom: 5 };
    coordinates.TC = { latitude: 21.694025, longitude: -71.797928, zoom: 5 };
    coordinates.TD = { latitude: 15.454166, longitude: 18.732207, zoom: 5 };
    coordinates.TF = { latitude: -49.280366, longitude: 69.348557, zoom: 5 };
    coordinates.TG = { latitude: 8.619543, longitude: 0.824782, zoom: 5 };
    coordinates.TH = { latitude: 15.870032, longitude: 100.992541, zoom: 5 };
    coordinates.TJ = { latitude: 38.861034, longitude: 71.276093, zoom: 5 };
    coordinates.TK = { latitude: -8.967363, longitude: -171.855881, zoom: 5 };
    coordinates.TL = { latitude: -8.874217, longitude: 125.727539, zoom: 5 };
    coordinates.TM = { latitude: 38.969719, longitude: 59.556278, zoom: 5 };
    coordinates.TN = { latitude: 33.886917, longitude: 9.537499, zoom: 5 };
    coordinates.TO = { latitude: -21.178986, longitude: -175.198242, zoom: 5 };
    coordinates.TR = { latitude: 38.963745, longitude: 35.243322, zoom: 5 };
    coordinates.TT = { latitude: 10.691803, longitude: -61.222503, zoom: 5 };
    coordinates.TV = { latitude: -7.109535, longitude: 177.64933, zoom: 5 };
    coordinates.TW = { latitude: 23.69781, longitude: 120.960515, zoom: 5 };
    coordinates.TZ = { latitude: -6.369028, longitude: 34.888822, zoom: 5 };
    coordinates.UA = { latitude: 48.379433, longitude: 31.16558, zoom: 5 };
    coordinates.UG = { latitude: 1.373333, longitude: 32.290275, zoom: 5 };
    coordinates.US = { latitude: 37.09024, longitude: -95.712891, zoom: 3 };
    coordinates.UY = { latitude: -32.522779, longitude: -55.765835, zoom: 5 };
    coordinates.UZ = { latitude: 41.377491, longitude: 64.585262, zoom: 5 };
    coordinates.VA = { latitude: 41.902916, longitude: 12.453389, zoom: 5 };
    coordinates.VC = { latitude: 12.984305, longitude: -61.287228, zoom: 5 };
    coordinates.VE = { latitude: 6.42375, longitude: -66.58973, zoom: 5 };
    coordinates.VG = { latitude: 18.420695, longitude: -64.639968, zoom: 5 };
    coordinates.VI = { latitude: 18.335765, longitude: -64.896335, zoom: 5 };
    coordinates.VN = { latitude: 14.058324, longitude: 108.277199, zoom: 5 };
    coordinates.VU = { latitude: -15.376706, longitude: 166.959158, zoom: 5 };
    coordinates.WF = { latitude: -13.768752, longitude: -177.156097, zoom: 5 };
    coordinates.WS = { latitude: -13.759029, longitude: -172.104629, zoom: 5 };
    coordinates.XK = { latitude: 42.602636, longitude: 20.902977, zoom: 5 };
    coordinates.YE = { latitude: 15.552727, longitude: 48.516388, zoom: 5 };
    coordinates.YT = { latitude: -12.8275, longitude: 45.166244, zoom: 5 };
    coordinates.ZA = { latitude: -30.559482, longitude: 22.937506, zoom: 5 };
    coordinates.ZM = { latitude: -13.133897, longitude: 27.849332, zoom: 5 };
    coordinates.ZW = { latitude: -19.015438, longitude: 29.154857, zoom: 5 };
    return coordinates;
  };

  const coordinateLookup = getCoordinateLookup();

  const regionCoordinates = {
    lat: coordinateLookup[regionIso]?.latitude,
    lng: coordinateLookup[regionIso]?.longitude,
  };
  const regionZoom = coordinateLookup[regionIso]?.zoom;

  const filterAsinsWithType = (theType, theSellers, theAsins, theCategory) => {
    if (theType === null && theCategory === null) {
      setFilteredAsins(asins);
      return asins;
    }
    const newAsins = [];
    theSellers.forEach((s) =>
      s?.asins.forEach((o) => {
        let name = o.asin;
        let foundInCategory = !theCategory;
        for (let i = 0; i < theAsins.length; i += 1) {
          if (theAsins[i].asin === o.asin) {
            name = theAsins[i].name;
            if (theCategory && theAsins[i].categoryId === theCategory) {
              foundInCategory = true;
            }
            break;
          }
        }
        if (foundInCategory) {
          newAsins.push({ asin: o.asin, name });
        }
      }),
    );
    const uniqueAsins = newAsins.filter((e, i, a) => a.findIndex((t) => t.asin === e.asin) === i);
    setFilteredAsins(uniqueAsins);
    return uniqueAsins;
  };

  const filterSellers = (t, theSellers) => {
    if (t === null) {
      setFilteredSellers(theSellers);
      return theSellers;
    }
    const filtered = theSellers.filter((s) => s.currentScore?.score < 0);
    setFilteredSellers(filtered);
    return filtered;
  };

  const filterReferenceAddresses = (markersArray, showRefAddresses) => {
    if (!showRefAddresses) {
      setFilteredMarkers(markersArray.filter((a) => !a.type || a.type !== 'reference'));
    } else {
      const newArray = markersArray.concat(referenceAddresses);
      setFilteredMarkers(newArray);
    }
  };

  const filterMarkers = (markersArray, theAsins, theSellers, theCategory, theAsin, theType) => {
    if (theCategory === null && theAsin === null && theType === null) {
      filterReferenceAddresses(markersArray, showReferenceAddresses);
    } else if (theAsin === null) {
      const asinList = theAsins.map((a) => a.asin);
      const filtered = [];
      theSellers.forEach((s) =>
        s?.asins.forEach((o) => {
          if (asinList.includes(o.asin)) {
            const p = markersArray.find((b) => b?.amazonId === s.id);
            if (p) {
              let found = false;
              for (let i = 0; i < filtered.length; i += 1) {
                const m = filtered[i];
                if (m.amazonId === p.amazonId) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                if (p) filtered.push(p);
              }
            }
          }
        }),
      );
      for (let i = 0; i < markersArray.length; i += 1) {
        const marker = markersArray[i];
        if (!marker.amazonId) {
          filtered.push(marker);
        }
      }
      filterReferenceAddresses(filtered, showReferenceAddresses);
    } else {
      const filtered = [];
      theSellers.forEach((s) =>
        s?.asins.forEach((o) => {
          if (o.asin === asin) {
            const p = markersArray.find((b) => b?.amazonId === s.id);
            if (p) {
              let found = false;
              for (let i = 0; i < filtered.length; i += 1) {
                const m = filtered[i];
                if (m.amazonId === p.amazonId) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                if (p) filtered.push(p);
              }
            }
          }
        }),
      );
      for (let i = 0; i < markersArray.length; i += 1) {
        const marker = markersArray[i];
        if (!marker.amazonId) {
          filtered.push(marker);
        }
      }
      filterReferenceAddresses(filtered, showReferenceAddresses);
    }
  };

  const sellerHasLowScore = (s, theSellers) => {
    for (let i = 0; i < theSellers.length; i += 1) {
      const seller = theSellers[i];
      if (seller.id === s.id) {
        return seller.currentScore?.score < 0;
      }
    }
    return false;
  };

  const GET_MAP_DATA_QUERY = gql`
    query GetMapData {
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getAsinsForAccount(premiumTiers: true) {
        asin
        categoryId
      }
      getSellersForAccount {
        asins {
          asin
          categoryId
        }
        id
        name
        coordinates
        businessAddress
        currentScore {
          score
          harvestDate
        }
      }
      getCategoryList(premiumTiers: true) {
        id
        name
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const markersArray = [];
      const sortedCategories = dataToProcess.getCategoryList
        ?.concat()
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories);
      const asinList = new Set();
      const uniqueAsins = dataToProcess.getAsinsForAccount?.filter((e) =>
        asinList.has(e?.asin) ? false : asinList.add(e?.asin),
      );
      setData(dataToProcess.getAsinsForAccount);
      setAsins(uniqueAsins);
      setFilteredAsins(uniqueAsins);
      const sellersForAccount = dataToProcess.getSellersForAccount;
      const businessAddresses = dataToProcess.getAddresses;
      setSellers(sellersForAccount);

      sellersForAccount?.forEach((seller) => {
        const coordinates = seller.coordinates?.split(',');
        if (coordinates?.length > 1) {
          const lat = parseFloat(coordinates[0]);
          const lng = parseFloat(coordinates[1]);
          if (lat && lng) {
            markersArray.push({
              type: sellerHasLowScore(seller, sellersForAccount) ? 'bad' : 'good',
              icon: sellerHasLowScore(seller, sellersForAccount) ? markerRed : markerBlue,
              title: seller.businessAddress,
              name: seller?.name,
              amazonId: seller.id,
              lat,
              lng,
              onClick: () => {
                setSellerProfile({
                  sellerId: seller?.id,
                  sellerName: seller?.name,
                });
              },
            });
          }
        }
      });

      if (!businessAddresses) {
        setToast({ type: 'error', message: 'Sorry an error has occurred' });
      } else {
        const refAddressArray = [];
        businessAddresses.forEach((address) => {
          const coordinates = address.coordinates?.split(',');
          if (
            coordinates?.length > 1 &&
            (address?.addressTypeId === 3 || address?.addressTypeId === 2)
          ) {
            const lat = parseFloat(coordinates[0]);
            const lng = parseFloat(coordinates[1]);
            if (lat && lng) {
              let displayAddress = address?.addressLine1 || '';
              if (address?.addressLine2) {
                displayAddress += `, ${address.addressLine2}`;
              }
              if (address?.city) {
                displayAddress += `, ${address.city}, ${address?.state} ${address?.zip}`;
              }
              refAddressArray.push({
                type: 'reference',
                icon: markerGray,
                title: displayAddress,
                name:
                  address.description ??
                  (address.addressTypeId === 3 ? 'Business Address' : 'Reference Address'),
                lat,
                lng,
                amazonId: `reference-${address.id}`,
              });
            }
          }
        });
        setReferenceAddresses(refAddressArray);
      }
      const theSellers = filterSellers(type, sellersForAccount);
      const filtered = filterAsinsWithType(type, theSellers, uniqueAsins, category);
      if (showReferenceAddresses) {
        const newArray = markersArray.concat(referenceAddresses);
        setMarkers(newArray);
        filterMarkers(newArray, filtered, theSellers, category, null, type);
      } else {
        setMarkers(markersArray);
        filterMarkers(markersArray, filtered, theSellers, category, null, type);
      }
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_MAP_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  const handleChooseAsin = (a) => {
    filterMarkers(markers, filteredAsins, filteredSellers, category, a, type);
    setAsin(a);
  };

  const handleChooseCategory = (c) => {
    if (c === null) {
      const theSellers = filterSellers(type, sellers);
      const filtered = filterAsinsWithType(type, filterSellers(type, sellers), asins, c);
      filterMarkers(markers, filtered, theSellers, c, asin, type);
      setAsin(null);
    } else {
      const newAsins = [];
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];
        if (item.categoryId === c) {
          newAsins.push({ asin: item.asin, name: item.name });
        }
      }
      const theSellers = filterSellers(type, sellers);
      const filtered = filterAsinsWithType(type, filterSellers(type, sellers), asins, c);
      filterMarkers(markers, filtered, theSellers, c, asin, type);
      setAsin(null);
    }
    setCategory(c);
  };

  const handleChooseType = (t) => {
    setType(t);
    setAsin(null);
    setCategory(null);
    const theSellers = filterSellers(t, sellers);
    const filtered = filterAsinsWithType(t, theSellers, asins, null);
    filterMarkers(markers, filtered, theSellers, null, null, t);
  };

  const handleRefAddChange = (e) => {
    const checked = e.target.checked;
    setShowReferenceAddresses(checked);
    filterReferenceAddresses(filteredMarkers, checked);
  };

  return (
    <Widget>
      <WidgetHeader
        title="Seller Map"
        showLine
        bottom={
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FilterDropdown
                label="Type"
                items={typeOptions}
                value={type}
                onSelect={handleChooseType}
                datacy="select_dropdown_option_Type"
              />
              <FilterDropdown
                label="Category"
                items={categories?.map((c) => ({
                  value: c?.id,
                  title: c?.name,
                }))}
                value={category}
                datacy="select_dropdown_option_Category"
                onSelect={handleChooseCategory}
              />
              <FilterDropdown
                label="ASIN"
                items={filteredAsins?.map((a) => ({
                  value: a?.asin,
                  title: a?.asin,
                  tooltip: a?.name,
                }))}
                value={asin}
                datacy="select_dropdown_option_ASIN"
                onSelect={handleChooseAsin}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: -1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={handleRefAddChange}
                    checked={showReferenceAddresses}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: 'greys.black',
                    }}
                  >
                    Reference Addresses
                  </Typography>
                }
                labelPlacement="end"
              />
            </Box>
          </Box>
        }
      />
      {loading && (
        <Box
          sx={{
            flexGrow: '1',
            overflow: 'auto',
            minHeight: '100%',
            px: 3,
            pt: 2,
            pb: 3,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '320px',
          }}
        >
          <Loading />
        </Box>
      )}
      {!loading && (
        <MapGoogle
          center={regionCoordinates}
          zoom={regionZoom}
          markers={filteredMarkers}
          height="320px"
        />
      )}
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfile !== null}
        onClose={() => setSellerProfile(null)}
      />
    </Widget>
  );
};

export default SellersMap;
