import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Loading, ConfirmationDialog } from 'components';
import {
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { getCurrentDateTime, formatDate } from 'utils/dates';
import InfiniteScroll from 'react-infinite-scroller';
import { useQuery, useMutation, gql } from '@apollo/client';
import { formatStringForCSV } from 'utils/strings';
import { CSVLink } from 'react-csv';
import ClientRow from './ClientRow';
import AddClient from './AddClient';
import ClientDrawer from './ClientDrawer';

const ClientsContent = ({ tenant }) => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [clients, setClients] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const [searchText, setSearchText] = useState('');
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [standardSlots, setStandardSlots] = useState(null);
  const [lowPrioritySlots, setLowPrioritySlots] = useState(null);
  const [highPrioritySlots, setHighPrioritySlots] = useState(null);
  const [totalAsins, setTotalAsins] = useState(null);
  const [orderBy, setOrderBy] = useState('name');
  const [sortOrder, setOrder] = useState('asc');
  const theme = useTheme();
  const { account, hasPermission } = useContext(AuthDataContext);
  const [showAddClient, setShowAddClient] = useState(false);
  const [deleteConfirmationClient, setDeleteConfirmationClient] = useState(null);
  const [showClient, setShowClient] = useState(null);
  const tenantId = tenant ? tenant.id : null;

  const headCells = [
    { id: 'name', label: 'Client', numeric: false, sortable: true, width: '25%', align: 'left' },
    {
      id: 'userCount',
      label: 'Users',
      numeric: true,
      sortable: false,
      width: '10%',
      align: 'center',
    },
    {
      id: 'asinCount',
      label: <Box>ASIN&nbsp;Count</Box>,
      numeric: true,
      sortable: false,
      width: '15%',
      align: 'center',
    },
    {
      id: 'slotsPerTier',
      label: <Box>Slots&nbsp;Per&nbsp;Tier</Box>,
      numeric: true,
      sortable: false,
      width: '15%',
      align: 'center',
    },
    {
      id: 'lastLoggedIn',
      label: 'Last Login',
      numeric: false,
      sortable: false,
      width: '10%',
      align: 'center',
    },
    hasPermission('clientManagement')
      ? {
          id: 'fetchInventory',
          label: 'Inventory',
          numeric: true,
          sortable: true,
          width: '5%',
          align: 'center',
        }
      : { id: 'fetchInventory' },
    hasPermission('clientManagement')
      ? {
          id: 'features',
          label: 'Features',
          numeric: false,
          sortable: false,
          width: '20%',
          align: 'left',
        }
      : { id: 'features' },
    { id: 'actions', label: '', numeric: false, sortable: false, width: '5%', align: 'right' },
  ];

  const sortHandler = (headCell, dataToProcess, order) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = orderBy === headCell.id && order === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(headCell.id);
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (headCell.id === 'currentScore') {
        if (!isAsc) {
          return b[headCell.id].score - a[headCell.id].score;
        }
        return a[headCell.id].score - b[headCell.id].score;
      }
      if (headCell.id === 'asins') {
        if (!isAsc) {
          return b[headCell.id].length - a[headCell.id].length;
        }
        return a[headCell.id].length - b[headCell.id].length;
      }
      if (headCell.numeric) {
        if (!isAsc) {
          return b[headCell.id] - a[headCell.id];
        }
        return a[headCell.id] - b[headCell.id];
      }
      if (!isAsc) {
        return b[headCell.id].localeCompare(a[headCell.id]);
      }
      return a[headCell.id].localeCompare(b[headCell.id]);
    });
    setFiltered(sorted);
  };

  const handleFilter = (dataToFilter, searchKeywords) => {
    const searchData = dataToFilter.filter((seller) => {
      const tokens = searchKeywords?.toLowerCase().split(' ');
      let tokensFound = 0;
      for (let i = 0; i < tokens.length; i += 1) {
        const token = tokens[i];
        if (seller.objString.toLowerCase().includes(token)) {
          tokensFound += 1;
        }
      }
      return tokensFound === (tokens ? tokens?.length : 0);
    });
    setFiltered(searchData);
    sortHandler(headCells[0], searchData, 'asc');
  };

  const processData = (dataToProcess) => {
    const clientsWithString = dataToProcess.getSubtenants.map((c) => ({
      ...c,
      objString: `${c.name} ${c.users.map(
        (i) => `${i.email} ${i.firstName ? ` (${i.firstName} ${i.lastName})` : ''}`,
      )}`,
    }));
    setClients(clientsWithString);
    setFiltered(clientsWithString);
    sortHandler(headCells[0], clientsWithString, 'asc');
    handleFilter(clientsWithString, searchText);
    let standard = 0;
    let low = 0;
    let high = 0;
    let total = 0;
    for (let i = 0; i < clientsWithString.length; i += 1) {
      const client = clientsWithString[i];
      if (!client.disabled) {
        total += client.asinCount;
        for (let j = 0; j < client.tiers.length; j += 1) {
          const tier = client.tiers[j];
          if (tier.tier === 'legacy') {
            standard += tier.slots;
          }
          if (tier.tier === 'lowPriority') {
            low += tier.slots;
          }
          if (tier.tier === 'highPriority') {
            high += tier.slots;
          }
        }
      }
    }
    if (standard > 0) {
      setStandardSlots(standard);
    }
    if (low > 0) {
      setLowPrioritySlots(low);
    }
    if (high > 0) {
      setHighPrioritySlots(high);
    }
    setTotalAsins(total);
    setLoading(false);
  };

  const GET_CLIENTS_QUERY = gql`
    query GetClients($id: ID!) {
      getSubtenants(id: $id) {
        id
        name
        users {
          id
          email
          firstName
          lastName
          lastLoggedIn
        }
        asinCount
        fetchInventory
        disabled
        logo
        tiers {
          tier
          description
          slots
          asinCount
        }
        demo
        externalEnforcementRoutingEmail
        configuredFeatures {
          id
          name
          description
        }
        configuredRegions {
          id
          currency
          baseUrl
          iso
          name
          nativeName
        }
      }
    }
  `;

  const { refetch } = useQuery(GET_CLIENTS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: typeof account !== 'object' || !tenant,
    variables: { id: tenantId },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const DELETE_CLIENT_MUTATION = gql`
    mutation DeleteAccount($id: Int!) {
      deleteAccount(accountId: $id)
    }
  `;

  const [deleteClient] = useMutation(DELETE_CLIENT_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const onSearch = (text) => {
    setSearchText(text);
    handleFilter(clients, text);
  };

  const handleDeleteClient = (c) => {
    setDeleting(true);
    deleteClient({
      variables: {
        id: c.id,
      },
      onCompleted: () => {
        refetch();
        setDeleting(false);
        setDeleteConfirmationClient(null);
      },
    });
  };

  const loadMore = () => {
    if (limit < filtered.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filtered.length) {
        newLimit = filtered.length;
      }
      setLimit(newLimit);
    }
  };

  const getLastLoggedIn = (client) => {
    let lastLoggedIn = null;
    for (let i = 0; i < client.users?.length; i += 1) {
      const user = client.users[i];
      if (user.lastLoggedIn) {
        if (!lastLoggedIn) {
          lastLoggedIn = new Date(user.lastLoggedIn);
        }
        const userLastLoggedIn = new Date(user.lastLoggedIn);
        if (!lastLoggedIn || userLastLoggedIn > lastLoggedIn) {
          lastLoggedIn = userLastLoggedIn;
        }
      }
    }
    return lastLoggedIn;
  };

  const exportData = (data) => {
    const csvData = [['Client', 'Users', 'ASIN Count', 'Slots Per Tier', 'Last Login']];
    if (account?.fetchInventory) {
      csvData[0].push('Inventory');
    }
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const login = getLastLoggedIn(item);
      const lastLogin = login ? formatDate(login) : 'None';
      const asinCounts = item.tiers.map(tier => `${tier.description}: ${tier.asinCount}`).join(', ');
      const slots = item.tiers.map(tier => `${tier.description}: ${tier.slots}`).join(', ');
      const itemToAdd = [
        formatStringForCSV(item.name),
        item.users.length,
        formatStringForCSV(asinCounts),
        formatStringForCSV(slots),
        lastLogin,
      ];
      if (item?.fetchInventory) {
        itemToAdd.push('Yes');
      } else {
        itemToAdd.push('-');
      }
      csvData.push(itemToAdd);
    }
    return csvData;
  };

  const getSlotInformation = () => {
    if (tenant.tenant === 'ipsecure') {
      return [];
    }
    const results = [];
    if (standardSlots) {
      results.push(`Standard: ${standardSlots.toLocaleString('en-US')}`);
    }
    if (lowPrioritySlots) {
      results.push(`Low Priority: ${lowPrioritySlots.toLocaleString('en-US')}`);
    }
    if (highPrioritySlots) {
      results.push(`High Priority: ${highPrioritySlots.toLocaleString('en-US')}`);
    }
    return results.join(', ');
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box sx={{ color: 'greys.silver', fontSize: '14px' }}>
          {getSlotInformation().length > 0 && getSlotInformation()}
          {totalAsins && (
            <Box>
              ASINs active: {totalAsins.toLocaleString('en-US')}
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            disabled={loading}
            multiline
            type="text"
            onChange={(event) => onSearch(event.target.value)}
            placeholder="Search"
            data-cy="tenants_search_bar"
            value={searchText}
            sx={{ width: '30ch' }}
            InputProps={{
              sx: {
                pl: 1,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fill={theme.palette.greys.silver} />
                </InputAdornment>
              ),
              endAdornment: searchText ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      onSearch('');
                    }}
                    size="large"
                  >
                    <ClearIcon fill={theme.palette.greys.silver} />
                  </IconButton>
                </InputAdornment>
              ) : (
                ''
              ),
            }}
          />
          <Button
            onClick={() => {
              setShowAddClient(true);
            }}
            data-cy="clients_add_client"
          >
            Add Client
          </Button>
          <CSVLink
            target="_blank"
            filename={`Clients-${getCurrentDateTime()}.csv`}
            data={exportData(filtered)}
          >
            <Button
              sx={{ borderColor: 'greys.lightGrey', height: '100%' }}
              variant="outlined"
              startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
              data-cy="tenants_clients_download"
            >
              Download
            </Button>
          </CSVLink>
        </Box>
      </Box>
      {loading && <Loading />}
      {!loading && (
        <TableContainer
          sx={{
            overflowY: 'scroll',
            height: 'calc(100vh - 232px)',
            overflowX: 'initial'
          }}
          data-cy="sellers_scroll"
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={limit < filtered.length}
            loader={limit < filtered.length && <Loading key={0} />}
            useWindow={false}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? sortOrder : false}
                      width={headCell.width}
                      align={headCell.align}
                    >
                      <TableSortLabel
                        hideSortIcon={!headCell.sortable}
                        data-cy={headCell.label}
                        active={headCell.sortable && orderBy === headCell.id}
                        direction={orderBy === headCell.id ? sortOrder : 'asc'}
                        onClick={() => {
                          sortHandler(headCell, filtered, sortOrder === 'asc' ? 'desc' : 'asc');
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-cy="sellers_table">
                {filtered.slice(0, limit).map((client, index) => (
                  <ClientRow
                    key={client.id}
                    client={client}
                    tenant={tenant}
                    index={index}
                    onShowClient={(c) => {
                      setShowClient(c);
                    }}
                    onDelete={setDeleteConfirmationClient}
                    getLastLoggedIn={getLastLoggedIn}
                  />
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
      {deleteConfirmationClient && (
        <ConfirmationDialog
          open
          busy={deleting}
          title="Delete Client"
          message={
            <Box sx={{ mt: 2 }}>
              Are you sure you wish to delete <b data-cy="delete_name">{deleteConfirmationClient?.name}</b> and all its
              data.
              <br />
              <b>THIS CANNOT BE UNDONE.</b>
              <br />
              This may take several minutes.
            </Box>
          }
          okTitle="Delete"
          destructive
          confirmDestructive="DELETE"
          onClose={(confirmed) => {
            if (confirmed) {
              handleDeleteClient(deleteConfirmationClient);
            } else {
              setDeleteConfirmationClient(null);
            }
          }}
        />
      )}
      {showAddClient && (
        <AddClient
          isOpen
          onClose={() => setShowAddClient(false)}
          onAdded={() => {
            setShowAddClient(false);
            refetch();
          }}
          tenant={tenant}
        />
      )}
      <ClientDrawer
        open={!!showClient}
        onClose={() => setShowClient(null)}
        id={showClient?.id}
        tenantId={tenant.id}
      />
    </Box>
  );
};

ClientsContent.defaultProps = {
  tenant: null,
};

ClientsContent.propTypes = {
  tenant: PropTypes.oneOfType([PropTypes.object]),
};

export default ClientsContent;
